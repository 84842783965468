<template>
  <div class="Question">
    <div
      class="question"
      :style="{ color: theme.primaryColor }">
      {{ $t(`currentGame.${currentTestName}`) }}
    </div>

    <div>
      <div
        class="MiniCard"
        :style="{ backgroundColor: doneMap.colorTest ? question.color : theme.primaryColor }">
        <div
          class="MiniCard__number"
          :class="{ done: doneMap.numberTest }">
          <div v-if="currentTestName === 'numberTest' && !doneMap.numberTest">
            <portal-target
              class="targetA"
              name="colorTestNumberInputTarget" />
          </div>
          <div v-else>
            <NumberComp
              :number="question.number"
              :mode="question.numberingStyle" />
          </div>
        </div>
        <div class="MiniCard__divider" />
        <div class="MiniCard__spacer" />
        <div
          class="MiniCard__text"
          :class="{ done: doneMap.wordTest }">
          <div v-if="currentTestName === 'wordTest'">
            {{ doneMap.wordTest ? question.word : '????' }}
          </div>
          <div v-else>
            {{ question.word }}
          </div>
        </div>
        <div class="MiniCard__spacer" />
      </div>
    </div>
  </div>
</template>

<script>
import last from 'lodash-es/last';
import NumberComp from '@/modules/games-shared/components/Number';

export default {
    components: { NumberComp },
    props: {
        itemIndex: {
            type: Number,
            required: true
        }
    },
    inject: ['module', 'theme'],
    computed: {
        doneMap() {
            const map = {};
            this.quizItem.tests.forEach(t => {
                map[t.name] = t.score;
            });
            return map;
        },
        session() {
            return this.module.state;
        },
        quizItem() {
            return this.module.getters('quiz/getItem')(this.itemIndex);
        },
        currentTestName() {
            return last(this.quizItem.tests).name;
        },
        question() {
            return this.session.quiz.sourceItems[this.quizItem.sourceItemIndex].data;
        }
    }
};
</script>

<style scoped lang="scss">
.Question {
    padding: 1em;
}

.question {
    font-size: 120%;
    text-align: center;
}
.MiniCard {
    color: black;
    padding: 1em;
    $size: 10em;
    width: $size;
    height: $size;
    display: flex;
    flex-direction: column;
    text-align: center;

    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
}

.MiniCard__number {
    font-size: 140%;
}
.MiniCard__divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
}
.MiniCard__spacer {
    flex: 1;
}
.MiniCard__text {
    line-height: 1.5em;
    color: mix(black, white, 80%);
}

.MiniCard__text,
.MiniCard__number {
    &:not(.done) {
        // opacity: 0;
    }
}
</style>
