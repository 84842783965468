function createLevelOptions({ numberOfItems, difficult = 0.5, colors = 0 }) {
    const complexity = colors ? 2 : 1;
    return {
        numberOfItems,
        itemMemorizeTimeLimit: 10 * complexity,
        itemQuizTimeLimit: 10 * complexity,
        difficult,
        colors,
        stars2: 7 * complexity,
        stars3: 5 * complexity
    };
}
const rawLevelOptions = [
    createLevelOptions({ numberOfItems: 3, difficult: 0 }), // 1.1
    createLevelOptions({ numberOfItems: 5, difficult: 0 }), // 1.2

    createLevelOptions({ numberOfItems: 10 }), // 2.1
    createLevelOptions({ numberOfItems: 15 }), // 2.2
    createLevelOptions({ numberOfItems: 20 }), // 2.3

    createLevelOptions({ numberOfItems: 20, colors: 3 }), // 3.1
    createLevelOptions({ numberOfItems: 25, colors: 3 }), // 3.2
    createLevelOptions({ numberOfItems: 30, colors: 3 }), // 3.3

    createLevelOptions({ numberOfItems: 35, colors: 6 }), // 4.1
    createLevelOptions({ numberOfItems: 40, colors: 6 }), // 4.2
    createLevelOptions({ numberOfItems: 45, colors: 6 }), // 4.3
    createLevelOptions({ numberOfItems: 50, colors: 6 }) // 4.4
];

export default function getLevelOptions(levelIndex) {
    return rawLevelOptions[levelIndex];
}
