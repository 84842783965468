import shuffle from 'lodash-es/shuffle';
import fill from 'lodash-es/fill';
import store from '@/store';

import words from './data/words';
import { splitString, getRandomInt, pickUniqueRandomItemsWithMemory } from '@/modules/memogenius-game/utils';

const colorArray = ['#ffee9e', '#80d643', '#4be9f4', '#25e8ae', '#f574db', '#fdb032'];

class QuestionService {
    getItems(numberOfItems, { difficult = 0, colors }) {
        const language = store.getters['moduleApp/locale'];
        const numberOfDifficultItems = Math.floor(difficult * numberOfItems);
        const numberOfEasyItems = numberOfItems - numberOfDifficultItems;
        let items = [];

        const state = store.state.moduleGameBrainstorm.questionServiceState;
        const usedMap = JSON.parse(JSON.stringify(state.usedMap || {}));

        pickUniqueRandomItemsWithMemory(words.hard, numberOfDifficultItems, {
            used: usedMap.hard,
            minimumAvailable: numberOfDifficultItems + 30,
            persist(used) {
                usedMap.hard = used;
            }
        }).forEach(sourceItem => {
            items.push({
                text: sourceItem[language],
                wordIsHard: true
            });
        });

        pickUniqueRandomItemsWithMemory(words.easy, numberOfEasyItems, {
            used: usedMap.easy,
            minimumAvailable: numberOfEasyItems + 30,
            persist(used) {
                usedMap.easy = used;
            }
        }).forEach(sourceItem => {
            items.push({
                text: sourceItem[language]
            });
        });

        store.dispatch('moduleGameBrainstorm/updateQuestionServiceState', { usedMap });

        items = shuffle(items);

        // NOTE: Ensures 50% of each test type
        let randomTestTypeArr = fill(Array(numberOfItems), 0);
        randomTestTypeArr = shuffle(fill(randomTestTypeArr, 1, Math.floor(numberOfItems / 2)));

        items.forEach((item, i) => {
            item.word = item.text;
            item.number = i + 1;
            if (randomTestTypeArr[i] === 1) {
                item.numberTest = {};
            } else {
                item.wordTest = {};
            }
        });

        if (colors) {
            items.forEach(item => {
                item.color = colorArray[getRandomInt(0, colors)];
                item.colorTest = {};
            });
        }

        return items;
    }

    getWordFragments(numberOfFragments, include = [], { hard = false }) {
        const language = store.getters['moduleApp/locale'];
        const fragments = [];
        const seen = {};
        const wordType = hard ? 'hard' : 'easy';

        // NOTE: fragments from 'include' does not have to be unique. Example: 'finfin' (no), where we need both parts to form the answer
        include.forEach(word => {
            const [part1, part2] = splitString(word);
            fragments.push(part1);
            seen[part1] = true;
            fragments.push(part2);
            seen[part2] = true;
        });

        while (fragments.length < numberOfFragments) {
            const word = words[wordType][getRandomInt(0, words[wordType].length)][language];
            const [part1, part2] = splitString(word);
            if (!seen[part1]) {
                fragments.push(part1);
                seen[part1] = true;
            }
            if (fragments.length === numberOfFragments) {
                break;
            }
            if (!seen[part2]) {
                fragments.push(part2);
                seen[part2] = true;
            }
        }

        return shuffle(fragments);
    }
    getColors(count, include = []) {
        return colorArray;
    }
}

const questionService = new QuestionService();
export default questionService;
