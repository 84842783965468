<template>
  <div class="DigitDisplay">
    <template v-for="(item, i) of items">
      <div
        v-if="item.type === 'slot'"
        :key="`slot${i}`"
        class="Slot"
        :class="{ hasFocus: item.index === carret }"
        @click="$emit('click-slot', item.index)">
        <div
          v-if="item.value === '#'"
          class="Slot__placeholder">
          {{ item.placeholder }}
        </div>
        <div
          v-else
          class="Slot__value">
          {{ item.value }}
        </div>
        <div class="Slot__line" />
      </div>
      <div
        v-else
        :key="`separator${i}`"
        class="Separator">
        <div class="Separator__symbol">
          {{ item.symbol }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        mask: {
            type: String
        },
        carret: {
            type: Number,
            default: -1
        }
    },
    computed: {
        items() {
            const items = [];
            let slotIndex = 0;
            if (this.mask) {
                Array.from(this.mask).forEach(x => {
                    if ('.-'.indexOf(x) !== -1) {
                        items.push({
                            type: 'separator',
                            symbol: x
                        });
                    } else {
                        items.push({
                            type: 'slot',
                            placeholder: x,
                            value: this.value[slotIndex] || '#',
                            index: slotIndex
                        });
                        slotIndex++;
                    }
                });
            } else {
                Array.from(this.value).forEach(x => {
                    items.push({
                        type: 'slot',
                        value: x,
                        index: slotIndex
                    });
                    slotIndex++;
                });
            }
            return items;
        }
    }
};
</script>

<style scoped lang="scss">
.DigitDisplay {
    display: flex;
}
.Slot {
    margin: 0 0.5em;

    &.hasFocus {
        .Slot__line {
            background-color: yellow;
        }
    }
}
.Slot__placeholder {
    color: rgba(white, 0.1);
}
.Slot__value,
.Slot__placeholder {
    font-size: 140%;
}
.Slot__line {
    height: 2px;
    background-color: rgba(white, 0.5);
}
.Separator {
    // padding: 1em;
}
.Separator__symbol {
    font-size: 140%;
}
</style>
