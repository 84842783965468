<template>
  <div class="ColorTest">
    <div class="colors">
      <div
        v-for="(color, i) of data.options"
        :key="i"
        class="Color --global-clickable"
        :class="{ selected: selections.indexOf(i) !== -1 }"
        :style="{ backgroundColor: color }"
        @click="selectOption(i)" />
    </div>
  </div>
</template>

<script>
import { TimelineMax, Power2 } from 'gsap';
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';

export default {
    inject: ['transition'],
    props: {
        done: {
            type: Boolean
        },
        score: {
            type: Number
        },
        data: {
            type: Object
        }
    },
    data() {
        return {
            selections: []
        };
    },
    watch: {
        done() {
            this.$nextTick(() => {
                if (this.score === 1) {
                    soundService.play(correctSound);
                    this.playFeedbackCorrect().then(() => {
                        this.$emit('feedback-completed');
                    });
                } else {
                    this.playFeedbackWrong().then(() => {
                        this.$emit('feedback-completed');
                    });
                }
            });
        }
    },
    beforeDestroy() {
        if (this.tl) {
            this.tl.kill();
        }
    },
    methods: {
        selectOption(optionIndex) {
            this.selections.push(optionIndex);
            this.transition('SUBMIT_ANSWER', { value: this.data.options[optionIndex] });
        },
        playFeedbackWrong() {
            // this.done = true
            return new Promise(resolve => {
                let shakeSpeed = 0.1;
                let elementsToShake = Array.from(this.$el.querySelectorAll('.Color.selected'));

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementsToShake, shakeSpeed, {
                    x: -7,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    repeat: 4,
                    x: 5,
                    yoyo: true,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    x: 0
                });
            });
        },
        playFeedbackCorrect() {
            // this.done = true
            return new Promise(resolve => {
                let elementsToAnimate = this.$el.querySelectorAll('.Color.selected');
                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementsToAnimate, 0.3, {
                    scale: 1.05,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToAnimate, 0.3, {
                    scale: 1,
                    ease: Power2.easeInOut
                });
            });
        },
        cheat() {
            this.transition('SUBMIT_ANSWER', { value: this.data.fasit });
        }
    }
};
</script>

<style scoped lang="scss">
.ColorTest {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.feedback {
    padding: 1em;
    background-color: red;

    &.correct {
        background-color: green;
    }
}
.colors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.Color {
    width: 4em;
    height: 4em;
    // border: 1px solid white;
    margin: 1em;
}
</style>
