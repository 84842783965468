export default {
    easy: [
        {
            en: 'accident',
            no: 'ulykke'
        },
        {
            en: 'adventure',
            no: 'eventyr'
        },
        {
            en: 'aggressive',
            no: 'aggressiv'
        },
        {
            en: 'alone',
            no: 'alene'
        },
        {
            en: 'anger',
            no: 'sinne'
        },
        {
            en: 'anxiety',
            no: 'angst'
        },
        {
            en: 'artistic',
            no: 'artistisk'
        },
        {
            en: 'bad',
            no: 'dårlig'
        },
        {
            en: 'beauty',
            no: 'skjønnhet'
        },
        {
            en: 'bend',
            no: 'bøye'
        },
        {
            en: 'big',
            no: 'stor'
        },
        {
            en: 'bizarre',
            no: 'bisarr'
        },
        {
            en: 'Blindly',
            no: 'blindt'
        },
        {
            en: 'bloated',
            no: 'oppsvulmet'
        },
        {
            en: 'brave',
            no: 'tapper'
        },
        {
            en: 'breathtaking',
            no: 'fantastisk'
        },
        {
            en: 'bright',
            no: 'smart'
        },
        {
            en: 'brilliant',
            no: 'strålende'
        },
        {
            en: 'brutal',
            no: 'brutal'
        },
        {
            en: 'bubbling',
            no: 'boblende'
        },
        {
            en: 'calm',
            no: 'rolig'
        },
        {
            en: 'chaotic',
            no: 'kaotisk'
        },
        {
            en: 'charity',
            no: 'veldedighet'
        },
        {
            en: 'cheap',
            no: 'billig'
        },
        {
            en: 'cheerful',
            no: 'munter'
        },
        {
            en: 'childhood',
            no: 'barndom'
        },
        {
            en: 'clever',
            no: 'klok'
        },
        {
            en: 'close',
            no: 'lukke'
        },
        {
            en: 'cloudy',
            no: 'skyet'
        },
        {
            en: 'coldness',
            no: 'kulde'
        },
        {
            en: 'comfort',
            no: 'komfort'
        },
        {
            en: 'comical',
            no: 'komisk'
        },
        {
            en: 'crazy',
            no: 'gal'
        },
        {
            en: 'crime',
            no: 'kriminalitet'
        },
        {
            en: 'cruel',
            no: 'grusom'
        },
        {
            en: 'customer',
            no: 'kunde'
        },
        {
            en: 'dangerous',
            no: 'farlig'
        },
        {
            en: 'death',
            no: 'død'
        },
        {
            en: 'defeat',
            no: 'nederlag'
        },
        {
            en: 'democracy',
            no: 'demokrati'
        },
        {
            en: 'desolate',
            no: 'øde'
        },
        {
            en: 'desperate',
            no: 'desperat'
        },
        {
            en: 'dictatorship',
            no: 'diktatur'
        },
        {
            en: 'disappointment',
            no: 'skuffelse'
        },
        {
            en: 'divine',
            no: 'guddommelig'
        },
        {
            en: 'dizzy',
            no: 'svimmel'
        },
        {
            en: 'dramatic',
            no: 'dramatisk'
        },
        {
            en: 'duty',
            no: 'plikt'
        },
        {
            en: 'education',
            no: 'utdanning'
        },
        {
            en: 'ego',
            no: 'ego'
        },
        {
            en: 'elastic',
            no: 'elastisk'
        },
        {
            en: 'elegant',
            no: 'elegant'
        },
        {
            en: 'embarrassing',
            no: 'pinlig'
        },
        {
            en: 'ending',
            no: 'slutt'
        },
        {
            en: 'energy',
            no: 'energi'
        },
        {
            en: 'enormos',
            no: 'enorm'
        },
        {
            en: 'envy',
            no: 'misunnelse'
        },
        {
            en: 'even',
            no: 'jevnt'
        },
        {
            en: 'evil',
            no: 'onde'
        },
        {
            en: 'expensive',
            no: 'dyrt'
        },
        {
            en: 'failure',
            no: 'svikt'
        },
        {
            en: 'faith',
            no: 'tro'
        },
        {
            en: 'fast',
            no: 'hurtig'
        },
        {
            en: 'fear',
            no: 'frykt'
        },
        {
            en: 'feedback',
            no: 'tilbakemelding'
        },
        {
            en: 'foggy',
            no: 'tåkete'
        },
        {
            en: 'freedom',
            no: 'frihet'
        },
        {
            en: 'friendship',
            no: 'vennskap'
        },
        {
            en: 'frightening',
            no: 'skremmende'
        },
        {
            en: 'generous',
            no: 'sjenerøs'
        },
        {
            en: 'glamorous',
            no: 'glamorøst'
        },
        {
            en: 'gloomy',
            no: 'dystert'
        },
        {
            en: 'goodness',
            no: 'godhet'
        },
        {
            en: 'grow',
            no: 'vokse'
        },
        {
            en: 'happiness',
            no: 'lykke'
        },
        {
            en: 'happy',
            no: 'glad'
        },
        {
            en: 'hard',
            no: 'hard'
        },
        {
            en: 'hatred',
            no: 'hat'
        },
        {
            en: 'haunted',
            no: 'hjemsøkt'
        },
        {
            en: 'hazy',
            no: 'disig'
        },
        {
            en: 'hilarious',
            no: 'morsom'
        },
        {
            en: 'homeless',
            no: 'hjemløs'
        },
        {
            en: 'honest',
            no: 'ærlig'
        },
        {
            en: 'honour',
            no: 'ære'
        },
        {
            en: 'hot',
            no: 'varmt'
        },
        {
            en: 'hour',
            no: 'time'
        },
        {
            en: 'humility',
            no: 'ydmykhet'
        },
        {
            en: 'humongous',
            no: 'kjempestor'
        },
        {
            en: 'humour',
            no: 'humor'
        },
        {
            en: 'hungry',
            no: 'sulten'
        },
        {
            en: 'hurt',
            no: 'vondt'
        },
        {
            en: 'idea',
            no: 'ide'
        },
        {
            en: 'illuminated',
            no: 'belyst'
        },
        {
            en: 'imagination',
            no: 'fantasi'
        },
        {
            en: 'industry',
            no: 'industri'
        },
        {
            en: 'inflation',
            no: 'inflasjon'
        },
        {
            en: 'innocent',
            no: 'uskyldig'
        },
        {
            en: 'Inquisitive',
            no: 'utspørrende'
        },
        {
            en: 'insanity',
            no: 'sinnssykdom'
        },
        {
            en: 'intelligence',
            no: 'intelligens'
        },
        {
            en: 'invisible',
            no: 'usynlig'
        },
        {
            en: 'inviting',
            no: 'innbydende'
        },
        {
            en: 'joy',
            no: 'glede'
        },
        {
            en: 'juicy',
            no: 'saftig'
        },
        {
            en: 'justice',
            no: 'rettferdighet'
        },
        {
            en: 'knowledge',
            no: 'kunnskap'
        },
        {
            en: 'law',
            no: 'lov'
        },
        {
            en: 'lazy',
            no: 'doven'
        },
        {
            en: 'life',
            no: 'liv'
        },
        {
            en: 'lift',
            no: 'løfte'
        },
        {
            en: 'loss',
            no: 'tap'
        },
        {
            en: 'loud',
            no: 'høyt'
        },
        {
            en: 'love',
            no: 'elsker'
        },
        {
            en: 'low',
            no: 'lavt'
        },
        {
            en: 'luck',
            no: 'hell'
        },
        {
            en: 'luxurious',
            no: 'luksuriøs'
        },
        {
            en: 'memory',
            no: 'minne'
        },
        {
            en: 'move',
            no: 'flytte'
        },
        {
            en: 'movement',
            no: 'bevegelse'
        },
        {
            en: 'music',
            no: 'musikk'
        },
        {
            en: 'naughty',
            no: 'slem'
        },
        {
            en: 'needy',
            no: 'trenger'
        },
        {
            en: 'Nervous',
            no: 'nervøs'
        },
        {
            en: 'open',
            no: 'åpne'
        },
        {
            en: 'pain',
            no: 'smerte'
        },
        {
            en: 'pale',
            no: 'blek'
        },
        {
            en: 'peace',
            no: 'fred'
        },
        {
            en: 'perfect',
            no: 'perfekt'
        },
        {
            en: 'pleasure',
            no: 'nytelse'
        },
        {
            en: 'Polite',
            no: 'høflig'
        },
        {
            en: 'polluted',
            no: 'forurenset'
        },
        {
            en: 'Poorly',
            no: 'dårlig'
        },
        {
            en: 'poverty',
            no: 'fattigdom'
        },
        {
            en: 'power',
            no: 'makt'
        },
        {
            en: 'precocious',
            no: 'veslevoksen'
        },
        {
            en: 'pull',
            no: 'dra'
        },
        {
            en: 'push',
            no: 'dytte'
        },
        {
            en: 'Quickly',
            no: 'raskt'
        },
        {
            en: 'radical',
            no: 'radikal'
        },
        {
            en: 'rebellious',
            no: 'opprørsk'
        },
        {
            en: 'receive',
            no: 'motta'
        },
        {
            en: 'refreshment',
            no: 'forfriskning'
        },
        {
            en: 'relaxation',
            no: 'avslapning'
        },
        {
            en: 'restoration',
            no: 'restaurering'
        },
        {
            en: 'rich',
            no: 'rik'
        },
        {
            en: 'romance',
            no: 'romantikk'
        },
        {
            en: 'sadness',
            no: 'tristhet'
        },
        {
            en: 'safe',
            no: 'Trygg'
        },
        {
            en: 'satisfaction',
            no: 'tilfredshet'
        },
        {
            en: 'sensitive',
            no: 'sensitive'
        },
        {
            en: 'shaking',
            no: 'ristende'
        },
        {
            en: 'shallow',
            no: 'grunt'
        },
        {
            en: 'Sharp',
            no: 'skarpt'
        },
        {
            en: 'shock',
            no: 'sjokk'
        },
        {
            en: 'shut',
            no: 'låse'
        },
        {
            en: 'silence',
            no: 'stille'
        },
        {
            en: 'slavery',
            no: 'slaveri'
        },
        {
            en: 'sleep',
            no: 'søvn'
        },
        {
            en: 'Slowly',
            no: 'sakte'
        },
        {
            en: 'small',
            no: 'liten'
        },
        {
            en: 'smell',
            no: 'lukt'
        },
        {
            en: 'sober',
            no: 'edru'
        },
        {
            en: 'soft',
            no: 'myk'
        },
        {
            en: 'sparkle',
            no: 'gnist'
        },
        {
            en: 'speed',
            no: 'hastighet'
        },
        {
            en: 'spicy',
            no: 'krydret'
        },
        {
            en: 'sport',
            no: 'idrett'
        },
        {
            en: 'stable',
            no: 'stabil'
        },
        {
            en: 'strange',
            no: 'pussig'
        },
        {
            en: 'strength',
            no: 'styrke'
        },
        {
            en: 'strict',
            no: 'streng'
        },
        {
            en: 'surprise',
            no: 'overraskelse'
        },
        {
            en: 'sweet',
            no: 'søt'
        },
        {
            en: 'thrill',
            no: 'spenning'
        },
        {
            en: 'tightly',
            no: 'tett'
        },
        {
            en: 'tiredness',
            no: 'tretthet'
        },
        {
            en: 'together',
            no: 'sammen'
        },
        {
            en: 'tough',
            no: 'tøff'
        },
        {
            en: 'unstable',
            no: 'ustabil'
        },
        {
            en: 'victory',
            no: 'seier'
        },
        {
            en: 'visionary',
            no: 'visjonær'
        },
        {
            en: 'watery',
            no: 'vassen'
        },
        {
            en: 'wealth',
            no: 'rikdom'
        },
        {
            en: 'Weekly',
            no: 'ukentlig'
        },
        {
            en: 'wet',
            no: 'vått'
        },
        {
            en: 'wildly',
            no: 'vilt'
        },
        {
            en: 'wisdom',
            no: 'visdom'
        },
        {
            en: 'wooly',
            no: 'ullen'
        },
        {
            en: 'awkward',
            no: 'ubehagelig'
        },
        {
            en: 'belief',
            no: 'tro'
        },
        {
            en: 'clarity',
            no: 'klarhet'
        },
        {
            en: 'content',
            no: 'tilfreds'
        },
        {
            en: 'encourage',
            no: 'oppmuntre'
        },
        {
            en: 'enlightened',
            no: 'opplyst'
        },
        {
            en: 'enthusiasm',
            no: 'entusiasme'
        },
        {
            en: 'fantastic',
            no: 'fantastisk'
        },
        {
            en: 'grief',
            no: 'sorg'
        },
        {
            en: 'mortal',
            no: 'dødelig'
        },
        {
            en: 'wonderful',
            no: 'fantastisk'
        },
        {
            en: 'yearly',
            no: 'årlig'
        }
    ],
    hard: [
        {
            en: 'ability',
            no: 'evne'
        },
        {
            en: 'abrupt',
            no: 'brå'
        },
        {
            en: 'abstract',
            no: 'abstrakt'
        },
        {
            en: 'absurd',
            no: 'absurd'
        },
        {
            en: 'accidentally',
            no: 'tilfeldigvis'
        },
        {
            en: 'always',
            no: 'alltid'
        },
        {
            en: 'amazement',
            no: 'forbauselse'
        },
        {
            en: 'amicable',
            no: 'minnelig'
        },
        {
            en: 'awe',
            no: 'ærefrykt'
        },
        {
            en: 'biased',
            no: 'partisk'
        },
        {
            en: 'blunt',
            no: 'direkte'
        },
        {
            en: 'aoastful',
            no: 'hoverende'
        },
        {
            en: 'communication',
            no: 'kommunikasjon'
        },
        {
            en: 'compassion',
            no: 'medfølelse'
        },
        {
            en: 'complicated',
            no: 'komplisert'
        },
        {
            en: 'concise',
            no: 'konsis'
        },
        {
            en: 'confidence',
            no: 'tillit'
        },
        {
            en: 'considerate',
            no: 'hensynsfull'
        },
        {
            en: 'consistent',
            no: 'konsistent'
        },
        {
            en: 'courage',
            no: 'mot'
        },
        {
            en: 'crude',
            no: 'rå'
        },
        {
            en: 'deceitful',
            no: 'svike'
        },
        {
            en: 'dedication',
            no: 'dedikasjon'
        },
        {
            en: 'defiantly',
            no: 'trassig'
        },
        {
            en: 'deliberately',
            no: 'bevisst'
        },
        {
            en: 'deviant',
            no: 'avvikende'
        },
        {
            en: 'devotedly',
            no: 'hengivende'
        },
        {
            en: 'dexterity',
            no: 'behendighet'
        },
        {
            en: 'disbelief',
            no: 'vantro'
        },
        {
            en: 'disturbance',
            no: 'forstyrrelse'
        },
        {
            en: 'diverse',
            no: 'mangfoldig'
        },
        {
            en: 'dogmatic',
            no: 'dogmatisk'
        },
        {
            en: 'Eager',
            no: 'ivrig'
        },
        {
            en: 'egotistical',
            no: 'selvopptatt'
        },
        {
            en: 'elaborate',
            no: 'utdypende'
        },
        {
            en: 'eloquent',
            no: 'veltalende'
        },
        {
            en: 'enchanting',
            no: 'fortryllende'
        },
        {
            en: 'enhancement',
            no: 'forsterkelse'
        },
        {
            en: 'exceptional',
            no: 'eksepsjonell'
        },
        {
            en: 'excitement',
            no: 'spenning'
        },
        {
            en: 'exquisite',
            no: 'utsøkt'
        },
        {
            en: 'extraordinary',
            no: 'ekstraordinær'
        },
        {
            en: 'fascination',
            no: 'fascinasjon'
        },
        {
            en: 'favouritism',
            no: 'favorisering'
        },
        {
            en: 'ferocious',
            no: 'glupsk'
        },
        {
            en: 'flawless',
            no: 'feilfri'
        },
        {
            en: 'foolish',
            no: 'tåpelig'
        },
        {
            en: 'forgiveness',
            no: 'tilgivelse'
        },
        {
            en: 'Fortunately',
            no: 'Heldigvis'
        },
        {
            en: 'fragility',
            no: 'sårbarhet'
        },
        {
            en: 'frailty',
            no: 'skrøpelighet'
        },
        {
            en: 'frantically',
            no: 'febrilsk'
        },
        {
            en: 'gracefully',
            no: 'grasiøst'
        },
        {
            en: 'gracious',
            no: 'elskverdig'
        },
        {
            en: 'hasty',
            no: 'forhastet'
        },
        {
            en: 'hearsay',
            no: 'rykter'
        },
        {
            en: 'helpfulness',
            no: 'hjelpsomhet'
        },
        {
            en: 'helplessness',
            no: 'hjelpeløshet'
        },
        {
            en: 'hopeless',
            no: 'håpløst'
        },
        {
            en: 'ideal',
            no: 'ideell'
        },
        {
            en: 'idealistic',
            no: 'idealistisk'
        },
        {
            en: 'impression',
            no: 'inntrykk'
        },
        {
            en: 'improvement',
            no: 'forbedring'
        },
        {
            en: 'inconsiderate',
            no: 'hensynsløs'
        },
        {
            en: 'indistinct',
            no: 'utydelig'
        },
        {
            en: 'inevitable',
            no: 'uunngåelig'
        },
        {
            en: 'inexperienced',
            no: 'uerfaren'
        },
        {
            en: 'infatuation',
            no: 'forelskelse'
        },
        {
            en: 'intolerant',
            no: 'intolerant'
        },
        {
            en: 'Irritable',
            no: 'irritert'
        },
        {
            en: 'jovial',
            no: 'jovial'
        },
        {
            en: 'Kindly',
            no: 'vennligst'
        },
        {
            en: 'lackluster',
            no: 'mangel'
        },
        {
            en: 'loyalty',
            no: 'lojalitet'
        },
        {
            en: 'ludicrous',
            no: 'latterlig'
        },
        {
            en: 'maturity',
            no: 'modenhet'
        },
        {
            en: 'mercy',
            no: 'nåde'
        },
        {
            en: 'mischievous',
            no: 'rampete'
        },
        {
            en: 'motivation',
            no: 'motivasjon'
        },
        {
            en: 'mundane',
            no: 'verdslig'
        },
        {
            en: 'murky',
            no: 'skummel'
        },
        {
            en: 'never',
            no: 'aldri'
        },
        {
            en: 'nondescriptive',
            no: 'ubestemmelig'
        },
        {
            en: 'obedient',
            no: 'lydig'
        },
        {
            en: 'obnoxious',
            no: 'ekkel'
        },
        {
            en: 'obscure',
            no: 'uklar'
        },
        {
            en: 'omen',
            no: 'tegn'
        },
        {
            en: 'omnipotent',
            no: 'allmektig'
        },
        {
            en: 'only',
            no: 'bare'
        },
        {
            en: 'opportunism',
            no: 'opportunisme'
        },
        {
            en: 'opportunity',
            no: 'mulighet'
        },
        {
            en: 'otherworldly',
            no: 'utenomjordisk'
        },
        {
            en: 'peculiar',
            no: 'særegen'
        },
        {
            en: 'pensive',
            no: 'tankefull'
        },
        {
            en: 'perplexing',
            no: 'forvirrende'
        },
        {
            en: 'perseverance',
            no: 'utholdenhet'
        },
        {
            en: 'Powerful',
            no: 'kraftfull'
        },
        {
            en: 'preposterous',
            no: 'vanvittig'
        },
        {
            en: 'principle',
            no: 'prinsipp'
        },
        {
            en: 'privileged',
            no: 'privilegert'
        },
        {
            en: 'promptly',
            no: 'snarlig'
        },
        {
            en: 'really',
            no: 'virkelig'
        },
        {
            en: 'reckless',
            no: 'uvøren'
        },
        {
            en: 'regularly',
            no: 'regelmessig'
        },
        {
            en: 'reliable',
            no: 'pålitelig'
        },
        {
            en: 'rude',
            no: 'frekk'
        },
        {
            en: 'sanity',
            no: 'tilregnelighet'
        },
        {
            en: 'seldom',
            no: 'sjelden'
        },
        {
            en: 'self-control',
            no: 'selvkontroll'
        },
        {
            en: 'Seriously',
            no: 'seriøst'
        },
        {
            en: 'silliness',
            no: 'dumhet'
        },
        {
            en: 'simple',
            no: 'enkel'
        },
        {
            en: 'sincere',
            no: 'oppriktig'
        },
        {
            en: 'skill',
            no: 'ferdighet'
        },
        {
            en: 'solemnly',
            no: 'høytidelig'
        },
        {
            en: 'sometimes',
            no: 'tidvis'
        },
        {
            en: 'sophistication',
            no: 'sofistikert'
        },
        {
            en: 'sorrow',
            no: 'sorg'
        },
        {
            en: 'speculation',
            no: 'spekulasjon'
        },
        {
            en: 'spontaneous',
            no: 'spontan'
        },
        {
            en: 'Steadily',
            no: 'stadig'
        },
        {
            en: 'stimulating',
            no: 'stimulerende'
        },
        {
            en: 'subtle',
            no: 'subtil'
        },
        {
            en: 'sympathetic',
            no: 'sympatisk'
        },
        {
            en: 'Tenderly',
            no: 'ømt'
        },
        {
            en: 'thoughtful',
            no: 'omtenksom'
        },
        {
            en: 'Totally',
            no: 'totalt'
        },
        {
            en: 'traumatic',
            no: 'traumatisk'
        },
        {
            en: 'trust',
            no: 'tillit'
        },
        {
            en: 'uncertainty',
            no: 'usikkerhet'
        },
        {
            en: 'unconventional',
            no: 'ukonvensjonell'
        },
        {
            en: 'unemployment',
            no: 'arbeidsledighet'
        },
        {
            en: 'unexpected',
            no: 'uventet'
        },
        {
            en: 'uninhibited',
            no: 'uhemmet'
        },
        {
            en: 'unpredictable',
            no: 'uforutsigbar'
        },
        {
            en: 'unusual',
            no: 'uvanlig'
        },
        {
            en: 'Usually',
            no: 'vanligvis'
        },
        {
            en: 'wary',
            no: 'skeptisk'
        },
        {
            en: 'adoration',
            no: 'beundring'
        },
        {
            en: 'apprehension',
            no: 'pågripelse'
        },
        {
            en: 'bewildered',
            no: 'forvirret'
        },
        {
            en: 'cryptic',
            no: 'kryptisk'
        },
        {
            en: 'curiosity',
            no: 'nysgjerrighet'
        },
        {
            en: 'dependent',
            no: 'avhengig'
        },
        {
            en: 'despair',
            no: 'Fortvilelse'
        },
        {
            en: 'determination',
            no: 'besluttsomhet'
        },
        {
            en: 'different',
            no: 'annerledes'
        },
        {
            en: 'disobedient',
            no: 'ulydig'
        },
        {
            en: 'doubtful',
            no: 'tvil'
        },
        {
            en: 'eccentric',
            no: 'eksentrisk'
        },
        {
            en: 'exactly',
            no: 'nøyaktig'
        },
        {
            en: 'faithful',
            no: 'trofast'
        },
        {
            en: 'faithless',
            no: 'troløs'
        },
        {
            en: 'finally',
            no: 'endelig'
        },
        {
            en: 'frequently',
            no: 'ofte'
        },
        {
            en: 'fussy',
            no: 'masete'
        },
        {
            en: 'gossip',
            no: 'sladder'
        },
        {
            en: 'hope',
            no: 'håp'
        },
        {
            en: 'horrible',
            no: 'fryktelig'
        },
        {
            en: 'impolite',
            no: 'uhøflig'
        },
        {
            en: 'independent',
            no: 'uavhengig'
        },
        {
            en: 'jealousy',
            no: 'sjalusi'
        },
        {
            en: 'kindness',
            no: 'godhet'
        },
        {
            en: 'lanky',
            no: 'hengslete'
        },
        {
            en: 'Loosely',
            no: 'løst'
        },
        {
            en: 'madness',
            no: 'galskap'
        },
        {
            en: 'moody',
            no: 'lunefull'
        },
        {
            en: 'mysterious',
            no: 'mystisk'
        },
        {
            en: 'often',
            no: 'ofte'
        },
        {
            en: 'opaque',
            no: 'ugjennomsiktig'
        },
        {
            en: 'opinion',
            no: 'mening'
        },
        {
            en: 'overcome',
            no: 'vinne'
        },
        {
            en: 'patience',
            no: 'tålmodighet'
        },
        {
            en: 'poetic',
            no: 'poetisk'
        },
        {
            en: 'priceless',
            no: 'uvurderlig'
        },
        {
            en: 'pride',
            no: 'stolthet'
        },
        {
            en: 'Rarely',
            no: 'sjelden'
        },
        {
            en: 'relief',
            no: 'lettelse'
        },
        {
            en: 'sacrifice',
            no: 'offer'
        },
        {
            en: 'selfish',
            no: 'egoistisk'
        },
        {
            en: 'sentimental',
            no: 'sentimental'
        },
        {
            en: 'service',
            no: 'tjeneste'
        },
        {
            en: 'sparkling',
            no: 'glitrende'
        },
        {
            en: 'stupidity',
            no: 'dumhet'
        },
        {
            en: 'submission',
            no: 'innsending'
        },
        {
            en: 'success',
            no: 'suksess'
        },
        {
            en: 'talent',
            no: 'talent'
        },
        {
            en: 'technically',
            no: 'teknisk'
        },
        {
            en: 'terrible',
            no: 'forferdelig'
        },
        {
            en: 'tolerance',
            no: 'toleranse'
        },
        {
            en: 'tremendous',
            no: 'enorm'
        },
        {
            en: 'value',
            no: 'verdi'
        },
        {
            en: 'weakness',
            no: 'svakhet'
        },
        {
            en: 'worried',
            no: 'bekymret'
        }
    ]
};
