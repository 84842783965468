<template>
  <div class="Card">
    <div class="spacerTop" />
    <div
      class="card"
      :style="{ backgroundColor: color }">
      <div class="number">
        <NumberComp
          :number="number"
          :mode="numberingStyle" />
      </div>
      <div class="divider" />
      <div class="spacer" />
      <div class="text">
        {{ text }}
      </div>
      <div class="spacer" />
    </div>
    <div class="spacerBottom" />
  </div>
</template>

<script>
import NumberComp from '@/modules/games-shared/components/Number';

export default {
    components: { NumberComp },
    props: {
        text: {
            type: String,
            required: true
        },
        number: {
            type: Number,
            required: true
        },
        color: {
            type: String
        },
        numberingStyle: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.Card {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.spacerTop {
    flex: 0.3;
}
.spacerBottom {
    flex: 1;
}
.card {
    padding: 1em;
    background-color: #e3e69e;
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;

    width: 20em;
    height: 20em;
}
.number {
    font-size: 140%;
}
.divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
}
.spacer {
    flex: 1;
}
.text {
    line-height: 1.5em;
    color: mix(black, white, 80%);
}
</style>
