<template>
  <div class="NumberTest">
    <!-- <portal-target class="targetA" :class="{ hasSide: layout.hasTopSide }" name="colorTestNumberInputTarget" xslim multiple/> -->
    <portal to="colorTestNumberInputTarget">
      <DigitDisplay
        :value="answer"
        :mask="mask"
        :carret="carret"
        @click-slot="setCarret" />
    </portal>
    <!-- <DigitDisplay :value="answer" :mask="mask" :carret="carret" @click-slot="setCarret"/> -->
    <br>
    <DigitPad
      class="DigitPad"
      @click-digit="setDigit" />
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';

import DigitPad from '@/modules/games-shared/components/DigitPad';
import DigitDisplay from '@/modules/games-shared/components/DigitDisplay';

export default {
    components: { DigitDisplay, DigitPad },
    inject: ['transition'],
    props: {
        done: {
            type: Boolean
        },
        score: {
            type: Number
        },
        data: {
            type: Object
        }
    },
    data() {
        const numberOfDigits = `${this.data.fasit}`.length;
        return {
            answer: '####'.substr(0, numberOfDigits),
            mask: '????'.substr(0, numberOfDigits),
            carret: 0
        };
    },
    watch: {
        done() {
            this.$nextTick(() => {
                if (this.score === 1) {
                    soundService.play(correctSound);
                    // this.$refs.input.playFeedbackCorrect().then(() => {
                    this.$emit('feedback-completed');
                    // })
                } else {
                    // this.$refs.input.playFeedbackWrong().then(() => {
                    this.$emit('feedback-completed');
                    // })
                }
            });
        }
    },
    methods: {
        setDigit(digit) {
            if (digit === 'X') {
                digit = '#';
            }
            this.answer = this.answer.substr(0, this.carret) + digit + this.answer.substr(this.carret + 1);
            if (this.carret === this.answer.length - 1) {
                // DONE
            } else {
                this.carret++;
            }

            if (this.answer.indexOf('#') === -1) {
                this.transition('SUBMIT_ANSWER', { value: parseInt(this.answer, 10) });
            }
        },
        setCarret(carretIndex) {
            this.carret = carretIndex;
        },
        cheat() {
            this.transition('SUBMIT_ANSWER', { value: this.data.fasit });
        }
    }
};
</script>

<style scoped lang="scss">
.NumberTest {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.feedback {
    padding: 1em;
    background-color: red;

    &.correct {
        background-color: green;
    }
}
.DigitDisplay {
    justify-content: center;
}
</style>
