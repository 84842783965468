import shuffle from 'lodash-es/shuffle';

import generateSessionReport from '@/modules/memogenius-game/generateSessionReport';

import questionService from './question-service';
import getLevelOptions from './get-level-options';

export default class Session {
    constructor(options) {
        if (options.levelIndex !== undefined) {
            this.levelIndex = options.levelIndex;
            this.options = getLevelOptions(this.levelIndex);
        } else {
            this.options = options;
        }
    }
    generateMemorize() {
        const { numberOfItems, itemMemorizeTimeLimit, difficult, colors, numberingStyle } = this.options;
        const memorizeTimeLimit = itemMemorizeTimeLimit * numberOfItems;

        const items = questionService.getItems(numberOfItems, {
            difficult,
            colors
        });
        if (numberingStyle) {
            items.forEach(item => {
                item.numberingStyle = numberingStyle;
            });
        }

        return {
            items: items.map(xx => {
                return {
                    memorizeItemType: 'GameBrainstorm-MemorizeItem',
                    timeLimit: itemMemorizeTimeLimit,
                    data: xx
                };
            }),
            timeLimit: memorizeTimeLimit,
            timeoutPerItem: true
        };
    }
    reportMemorize({ items, timeUsed }) {
        this.memorizeReport = { items, timeUsed };
    }
    generateQuiz() {
        const { numberOfItems, itemQuizTimeLimit } = this.options;
        const quizTimeLimit = itemQuizTimeLimit * numberOfItems;

        let useWordTestArr = [];
        for (let i = 0; i < numberOfItems; i++) {
            useWordTestArr.push(i < 0.5 * numberOfItems);
        }
        useWordTestArr = shuffle(useWordTestArr);

        const items = this.memorizeReport ? this.memorizeReport.items : this.generateMemorize().items;
        const sourceItems = items.map((item, i) => {
            return {
                quizItemType: 'GameBrainstorm-QuizItem',
                data: item.data,
                useWordTest: useWordTestArr[i],
                memorizeTime: item.timeUsed
                // numberingStyle
            };
        });

        return {
            sourceItems: shuffle(sourceItems),
            // shuffle: true,
            items: [],
            timeLimit: quizTimeLimit,
            lives: 3
        };
    }
    reportQuiz({ items, timeUsed, score, sourceItems }) {
        const { numberOfItems, stars2, stars3 } = this.options;
        const memorizeTime = this.memorizeReport && this.memorizeReport.timeUsed;
        this.reportedItems = items;
        let stars = 0;
        // NOTE: should not give stars if score < 1. But score wrongely reported, so ignored
        if (memorizeTime) {
            if (memorizeTime < stars3 * numberOfItems * 1000) {
                stars = 3;
            } else if (memorizeTime < stars2 * numberOfItems * 1000) {
                stars = 2;
            } else {
                stars = 1;
            }
        }
        this.quizReport = { items, timeUsed, stars, sourceItems };
    }
    generateReport() {
        return generateSessionReport({
            memorizeReport: this.memorizeReport,
            quizReport: this.quizReport,
            labels: { numberTest: 'Number', wordTest: 'Word', colorTest: 'Color' },
            initial(item, sourceItem) {
                return item.tests[0].name === 'wordTest'
                    ? { type: 'number', value: sourceItem.data.number }
                    : { type: 'word', value: sourceItem.data.word };
            },
            attempt(test) {
                if (test.name === 'colorTest') {
                    return {
                        type: 'color',
                        value: { color: test.submittedAnswer },
                        score: test.score
                    };
                } else if (test.name === 'wordTest') {
                    return {
                        type: 'word',
                        value: test.submittedAnswer,
                        score: test.score
                    };
                } else if (test.name === 'numberTest') {
                    return {
                        value: test.submittedAnswer,
                        score: test.score
                    };
                }
            }
        });
    }

    generateSessionOptionsForRetry() {
        return Object.assign({}, this.options, {});
    }
}
