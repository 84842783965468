import QuizItem from '@/modules/games-shared/components/QuizItem';

import Session from './Session';
import questionService from './question-service';
import Card from './components/Card';
import MemorizeItemInstruction from './components/MemorizeItemInstruction';
import Question from './components/Question';
import WordTest from './components/WordTest';
import NumberTest from './components/NumberTest';
import ColorTest from './components/ColorTest';

const quizMapping = {
    'GameBrainstorm-MemorizeItem': {
        component: Card,
        instructionComponent: MemorizeItemInstruction
    },
    'GameBrainstorm-QuizItem': {
        component: QuizItem,
        questionComponent: Question,
        generateTestNames(data, { sourceItem }) {
            const testNames = [];
            if (sourceItem.useWordTest) {
                testNames.push('wordTest');
            } else {
                testNames.push('numberTest');
            }
            if (data.color) {
                testNames.push('colorTest');
            }
            return testNames;
        },
        tests: {
            wordTest: {
                component: WordTest,
                generateTestData(data) {
                    const fasit = data.word.toLowerCase();
                    const fragments = questionService.getWordFragments(12, [fasit], { hard: data.wordIsHard });
                    return {
                        fasit,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 2,
                        fragments
                    };
                },
                calculateScore(data, answer) {
                    return data.word.toLowerCase() === answer ? 1 : 0;
                }
            },
            numberTest: {
                component: NumberTest,
                generateTestData(data) {
                    return {
                        fasit: data.number
                    };
                },
                calculateScore(data, answer) {
                    return data.number === answer ? 1 : 0;
                }
            },
            colorTest: {
                component: ColorTest,
                generateTestData(data) {
                    const options = questionService.getColors(6, [data.color]);
                    return {
                        fasit: data.color,
                        type: 'MultipleChoice',
                        requiredChoiceCount: 1,
                        options
                    };
                },
                calculateScore(data, answer) {
                    return data.color === answer ? 1 : 0;
                }
            }
        }
    }
};

export default {
    moduleName: 'moduleGameBrainstorm',
    Session,
    quizMapping
};
